<!-- @format -->

<template>
    <div
        class="main blank-layout container container--fluid main-content px-0 main-content"
        :class="{
            'main--show-header': showHeader,
            'py-0': !showHeader,
        }">
        <Header
            v-if="showHeader"
            :with-search="showHeaderSearch" />
        <slot></slot>
        <transition name="gst-fade-notification-terms">
            <NotificationTerms
                v-if="show"
                class="content-max-width" />
        </transition>
    </div>
</template>

<script>
import NotificationTermsLayoutMixin from '@tenant/mixins/NotificationTermsLayoutMixin';
import Header from './components/Header';

export default {
    name: 'BlankLayout',
    components: {
        Header,
    },
    mixins: [NotificationTermsLayoutMixin],
    computed: {
        showHeader() {
            return this.$vuetify.breakpoint.mdAndUp || !this.isEventPage;
        },
        isEventPage() {
            return this.$route.name.startsWith('event') || this.$route.name.startsWith('tm-event');
        },
        showHeaderSearch() {
            return this.$route.name !== 'cartCheckout';
        },
    },
};
</script>
