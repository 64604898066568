<!-- @format -->

<template>
    <v-app>
        <component :is="layout">
            <router-view v-if="!loading" />
            <v-overlay
                :value="loadingOverlay || loading"
                color="white"
                opacity="0.8"
                z-index="5002">
                <DataLoading size="40" />
            </v-overlay>
        </component>
        <div id="modals_container"></div>
        <NotificationContainer />
        <BackToTop :visible-offset-bottom="50" />
    </v-app>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import LocalStorageService from '@core/services/LocalStorageService';
import App from '@core/App.vue?CORE';
import iFrameService from '@tenant/services/iFrameService';

LocalStorageService.userToken.refresh();

export default {
    name: 'App',
    extends: App,
    data() {
        return {
            /**
             * mainContentHeight
             * It will be used to set up the IFrame Height
             */
            mainContentHeight: 0,
            fullViewportModal: false,
        };
    },
    computed: {
        ...mapState({
            notifications: (state) => state.notification.items.length,
        }),
        ...mapGetters({
            checkoutWizardCurrentStep: 'cart/checkoutWizardCurrentStep',
        }),
    },
    watch: {
        notifications: function (value, oldValue) {
            if (value.length > oldValue.length) {
                iFrameService.sendPageScrollEvent('top');
            }
        },
        checkoutWizardCurrentStep: function (value, oldValue) {
            if (value > oldValue) {
                iFrameService.sendPageScrollEvent('top');
            }
        },
        mainContentHeight: {
            handler: function (value, oldValue) {
                if (value !== oldValue && !this.isSplashScreenShown && !this.fullViewportModal)
                    iFrameService.sendPageResizeEvent(value);
            },
            immediate: false,
        },
    },
    methods: {
        calculateMainContentHeight: debounce(function () {
            const getContentHeight = () => {
                const heightViewportValue = 'viewport';
                const minHeight = 500;
                const blankLayout = document.getElementsByClassName('blank-layout')[0];
                const mainContentHeight =
                    document.getElementsByClassName('main-content')[0]?.scrollHeight || minHeight;

                if (blankLayout) {
                    const eventPageDiv = document.getElementsByClassName('gst-event')[0];
                    const confirmationPageDiv = document.getElementsByClassName(
                        'gst-checkout-confirmation'
                    )[0];
                    const checkoutPageDiv = document.getElementsByClassName('gst-checkout')[0];
                    if (eventPageDiv || confirmationPageDiv) {
                        return heightViewportValue;
                    }
                    if (checkoutPageDiv) {
                        const hasProgressAnimation = !!document.getElementsByClassName(
                            'gst-checkout-purchase-progress'
                        )[0];
                        if (hasProgressAnimation) {
                            return heightViewportValue;
                        } else {
                            return mainContentHeight;
                        }
                    }
                    return mainContentHeight;
                }

                return mainContentHeight;
            };

            this.mainContentHeight = getContentHeight();
        }, 20),
        addListenerForContentChanges() {
            const observer = new MutationObserver((mutationsList) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.calculateMainContentHeight();
                        break;
                    }
                }
            });
            // Start observing the target node for changes
            observer.observe(document.getElementById('app'), { childList: true, subtree: true });
        },
        addListenerForModalContainer() {
            const observer = new MutationObserver((mutationList) => {
                const modalContainer = mutationList[0].target;

                if (modalContainer.childNodes.length) {
                    const fullScreenModal = document.getElementsByClassName(
                        'v-dialog v-dialog--fullscreen'
                    )[0];
                    const bottomSheetModal =
                        document.getElementsByClassName('v-dialog v-bottom-sheet')[0];

                    if (fullScreenModal || bottomSheetModal) {
                        this.fullViewportModal = true;
                        iFrameService.sendPageResizeEvent('viewport');
                        iFrameService.sendPageScrollEvent('top');
                    } else {
                        iFrameService.sendPageScrollEvent('top');
                    }
                } else {
                    if (this.fullViewportModal) {
                        this.fullViewportModal = false;
                        iFrameService.sendPageResizeEvent(this.mainContentHeight);
                    }
                }
            });
            // Start observing the target node for changes
            observer.observe(document.getElementById('modals_container'), { childList: true });
        },
        saveUserToken() {
            const { _uid } = this.$route.query;

            if (_uid) {
                LocalStorageService.userToken.set(_uid);
            }
        },
    },
    mounted() {
        this.$router.onReady(() => {
            iFrameService.sendPageViewEvent();
            this.$router.afterEach(() => {
                this.saveUserToken();
                iFrameService.sendPageViewEvent();
            });
        });
        this.addListenerForContentChanges();
        this.addListenerForModalContainer();
    },
};
</script>
