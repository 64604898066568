import { getFromApiResponse as notificationUtilsGetFromApiResponse } from '@core/utils/notificationUtils';
import apiServiceCart from '@core/api/apiServiceCart';
import { normalizeCart } from '@core/data/response/normalizeCart';
import { normalizeOrder } from '@core/data/response/normalizeOrder';
import LocalStorageService from '@core/services/LocalStorageService';
import LogService from '@core/services/LogService';
import { normalizeShippingOption } from '@core/data/response/normalizeShippingOption';
import normalizeCartInvoice from '@core/data/response/normalizeCartInvoice';

const STORE_NAME = 'cart';

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        list: [],
        current: {
            id: null,
            expireAt: null,
            submittedAt: null,
            collections: [],
            order: {},
            isSubmitting: false //Flag to know if the cart is in submitting process.
        },
        checkoutWizardCurrentStep: 0
    },
    getters: {
        cartId: ( state ) => state.current.id,
        isCollectionIdIntoCart: ( state ) => id => state.current.collections.find ( item => item.id === id ),
        isExpired: ( state ) => ( ) => {
            const { expireAt } = state.current;

            return !expireAt || ( expireAt.getTime( ) < new Date( ).getTime( ) );
        },
        isSubmitted: ( state ) => !!state.current.submittedAt,
        isCartSubmitting: ( state ) => !!state.current.isSubmitting,
        checkoutWizardCurrentStep: ( state ) => state.checkoutWizardCurrentStep
    },
    mutations: {
        clear( state ) {
            state.current = {
                id: null,
                expireAt: null,
                submittedAt: null,
                collections: [],
                isSubmitting: false
            };
        },
        setCart( state, { cart, collections, order } ) {
            state.current = {
                ...cart,
                collections,
                order
            };
        },
        setCartSubmittedAt( state, value ) {
            state.current.submittedAt = value;
        },
        setCartIsSubmitting( state, value ) {
            state.current.isSubmitting = value;
        },
        addToList( state, data ) {
            state.list = [
                ...state.list,
                {
                    ...data
                }
            ];
        },
        removeFromList( state, id ) {
            state.list = state.list.filter( item => item.id !== id );
        },
        setCheckoutWizardCurrentStep ( state, currentStep ) {
            state.checkoutWizardCurrentStep = currentStep;
        },
    },
    actions: {
        get: async ( { commit, dispatch, state }, { id } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.get( id );

            if ( response.success ) {
                const { cart, collections, order } = normalizeCart( response.data );

                commit( 'setCart', {
                    cart: {
                        ...cart,
                        expireAt: state.current.expireAt,
                        submittedAt: state.current.submittedAt
                    },
                    collections,
                    order
                } );
                return state.current.id;
            } else {
                LogService.debug( 'Problem loading cart with id' + id );
                dispatch( 'notification/error', notificationUtilsGetFromApiResponse( response ), { root: true } );
                return false;
            }
        },
        createInvoice: async ( context, { id, data } ) => {
            /**
             * @param {Object} context
             * @param {*} param1
             * @param {String} param1.id cart id
             * @param {Object} param1.data cart data
             *
             * @returns {Promise<void>}
             */
            const api = await apiServiceCart( );
            const response = await api.cart.createInvoice( id, data );

            if ( response.success ) {
                return {
                    success: true,
                    data: normalizeCartInvoice( response.data )
                };
            } else {
                return response;
            }
        },
        clear: async ( { commit } ) => {
            commit( 'clear' );
        },
        setCheckoutWizardCurrentStep ( { commit }, currentStep ) {
            commit( 'setCheckoutWizardCurrentStep', currentStep );
        },
        addItem: async ( { commit, dispatch, state }, { ticket, hotelReservation, offer } ) => {
            if ( state.current && state.current.id ) {
                await dispatch( 'delete', { id: state.current.id } );
                commit( 'clear' );
            }
            const token = LocalStorageService.userToken.get( );
            const api = await apiServiceCart( '' );
            const response = await api.cart.addItem ( ticket, hotelReservation, offer, null, token );

            if ( response.success ) {
                const { cart, collections, order } = normalizeCart ( response.data );
                commit( 'setCart', {
                    cart: {
                        ...cart,
                        submittedAt: null
                    },
                    collections,
                    order
                } );
                commit( 'addToList', state.current );
                return { ...response, id: state.current.id };
            } else {
                dispatch( 'notification/error', notificationUtilsGetFromApiResponse( response ), { root: true } );
                return response;
            }
        },
        addShippingOption: async ( { commit, dispatch }, { id, data } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.addShippingOption( id, data );

            if ( response.success ) {
                const { cart, collections, order } = normalizeCart ( response.data );
                commit( 'setCart', {
                    cart: {
                        ...cart,
                        submittedAt: null
                    },
                    collections,
                    order
                } );

                return {
                    order
                };
            } else {
                LogService.debug( 'Problem adding shipping option' );
                dispatch( 'notification/error', notificationUtilsGetFromApiResponse( response ), { root: true } );
                return false;
            }
        },
        getShippingOptions: async ( { dispatch }, { id, data } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.getShippingOptions( id, data );

            if ( response.success ) {
                return response.data.map( normalizeShippingOption );
            } else {
                LogService.debug( 'Problem loading shipping options' );
                dispatch( 'notification/error', notificationUtilsGetFromApiResponse( response ), { root: true } );
                return false;
            }
        },
        checkout: async ( { commit }, { id, data } ) => {
            commit( 'setCartSubmittedAt', new Date() );
            commit( 'setCartIsSubmitting', true );
            const api = await apiServiceCart( '' );
            const response = await api.cart.checkout ( id, data );

            if ( response.success ) {
                commit( 'clear' );
                commit( 'removeFromList', id );
                return {
                    success: true,
                    ...normalizeOrder( response.data )
                };
            } else {
                commit( 'setCartIsSubmitting', false );
                return response;
            }
        },
        delete: async ( { commit, state }, { id } ) => {
            const api = await apiServiceCart( '' );
            const response = await api.cart.delete ( id );

            if ( response.success ) {
                state.id !== id || commit( 'clear' );
                commit( 'removeFromList', id );
                return id;
            } else {
                LogService.debug( 'Problem deleting cart with id ', id );
                return false;
            }
        },
        instantDelete: async( { commit, state }, { id } ) => {
            state.id || commit( 'clear' );
            commit( 'removeFromList', id );
            const api = await apiServiceCart( '' );
            const response = await api.cart.delete ( id );
            if ( response.success ) {
                return id;
            } else {
                LogService.debug( 'Problem deleting cart with id ', id );
                return false;
            }
        },
        instantDeleteCurrentCart: async( { state, commit, dispatch } ) => {
            const { id, isSubmitting } = state.current;

            // avoid cart deletion if submit process is running
            if ( id && !isSubmitting ) {
                commit( 'clear' );
                await dispatch( 'delete', { id } );
            }
            return true;
        },
        instantDeleteCurrentCartIfExpired: async( { getters, state, dispatch } ) => {
            const { id, expireAt } = state.current;

            if ( id && expireAt && getters[ 'isExpired' ]( ) ) {
                return await dispatch( 'instantDeleteCurrentCart' );
            }
            return true;
        },
        deleteAllIfExpired: async( { state, dispatch } ) => {
            const nowTimestamp = new Date( ).getTime( );

            state.list
                .filter( item => !item.expireAt || item.expireAt.getTime( ) < nowTimestamp )
                .forEach( item => {
                    dispatch( 'instantDelete', { id: item.id } );
                } );
        }
    },
    _persistent: {
        getDataToSave: ( state ) => {
            const storeState = state[ STORE_NAME ];
            const { list } = storeState;

            return {
                list: list.length ? list.map( item => {
                    return {
                        id: item.id,
                        expireAt: item.expireAt
                    };
                } ): [ ],
            };
        },
        getDataToLoad: ( data ) => {
            return {
                list: data.list ? data.list.map( item => {
                    return {
                        ...item,
                        expireAt: new Date( item.expireAt )
                    };
                } ): [ ],
            };
        }
    },
    _sessionPersist: {
        getDataToSave: ( state ) => {
            const storeState = state[ STORE_NAME ];
            const { current } = storeState;

            return {
                current: {
                    id: current.id,
                    expireAt: current.expireAt
                }
            };
        },
        getDataToLoad: ( data ) => {
            return {
                current: data.current ? {
                    ...data.current,
                    expireAt: new Date( data.current.expireAt )
                } : {
                    id: null,
                    expireAt: null,
                }
            };
        }
    }
};
