<!-- @format -->

<template>
    <div class="view-container gst-home">
        <SearchBanner />
        <div class="content-max-width">
            <div>
                <h4 class="gst-leagues-section-title mt-7 mb-6">
                    {{ $t('leagues.title') }}
                </h4>
                <div
                    class="gst-leagues-list d-flex align-start align-content-start wrap pa-1 u-overflow-auto-x">
                    <router-link
                        v-for="league in leagues"
                        :key="league.id"
                        class="u-box-shadow gst-league"
                        :to="{ name: 'leagues', params: { id: league.id } }">
                        <BaseIcon
                            :symbol-id="league.logo"
                            height="144"
                            width="144"
                            class="pa-4" />
                    </router-link>
                </div>
            </div>
            <component
                :is="RecentlyViewedEntities"
                v-if="!userFirstAccessApp"
                class="mt-10" />
        </div>

        <div class="content-max-width">
            <TopEventsCardList class="mt-6" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TopEventsCardList from '@core/views/home/TheHome/TopEventsCardList.vue';
import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
import LogService from '@core/services/LogService.js';
import SearchBanner from '@core/views/home/TheHome/SearchBanner.vue';
import apiServiceLeague from '@tenant/api/apiServiceLeague';

export default {
    name: 'Home',
    metaInfo: {
        title: 'Home',
    },
    i18nOptions: {
        namespaces: 'main',
        keyPrefix: 'views.home.theHome',
    },
    components: {
        TopEventsCardList,
        BaseIcon,
        SearchBanner,
    },
    data() {
        return {
            showOverlay: false,
            searchBannerVisible: false,
            bonusPointsHomeBannerVisible: false,
            leagues: [],
        };
    },
    computed: {
        ...mapGetters({
            recentlyViewedItems: 'user/browsingHistory/entitiesEventsAndAttraction',
        }),
        ...mapState({
            userFirstAccessApp: (state) => state.user.firstAccess,
        }),
        RecentlyViewedEntities() {
            return () => import('@core/views/home/TheHome/RecentlyViewedEntities.vue');
        },
    },
    methods: {
        async loadLeaguesData() {
            const response = await apiServiceLeague.get();
            if (response.success) {
                this.leagues = response.data;
            } else {
                LogService.error('Problems retrieving leagues');
            }
        },
    },
    created() {
        this.loadLeaguesData();
    },
};
</script>

<style lang="scss" scoped>
.gst-leagues-list {
    gap: 16px;

    .gst-league {
        border-radius: 13px;
        cursor: pointer;
    }
}
</style>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-banner--home {
    .gst-banner--home__ticketmaster-tickets-content {
        span {
            display: none !important;
        }
    }
}

@include mobile-only {
    .gst-banner--home {
        .gst-banner--home__ticketmaster-tickets-content {
            padding: 4px 16px !important;
            margin-bottom: 10px !important;

            svg {
                width: 200px !important;
            }
        }
    }
}
</style>
