import apiConstants from '@core/utils/constants/api';
import {
    normalizeQueryParams as apiUtilsNormalizeQueryParams,
} from '@core/utils/apiUtils';
import { createApiService, fetchAPI } from '@core/api/_utils';
import transformCartAddItem from '@core/data/request/transformCartAddItem';
import transformCartAddShippingOption from '@core/data/request/transformCartAddShippingOption';

let api = {
    cart: {
        get: ( id ) => {
            return fetchAPI.get( `${apiConstants.BASE_URL}/api/cart/${id}` );
        },
        /**
         * Add items to cart
         *
         * @param {Object} ticket
         * @param {Object} hotelReservation
         * @param {Object} offer
         * @param {Object} searchedLocation
         * @param {String} token - offer/tracking token.
         * In Ticketmaster - used as offer token,
         * in EE - used as tracking token
         */
        addItem: ( ticket, hotelReservation, offer, searchedLocation, token ) => {
            const eventId = ticket?.eventId ?? hotelReservation?.hotel?.id ;

            return fetchAPI.post(
                `${apiConstants.BASE_URL}/api/cart/reserve/${eventId}`,
                transformCartAddItem( ticket, hotelReservation, offer, searchedLocation, token )
            );
        },
        checkout: ( id, data ) => {
            return fetchAPI.post( `${apiConstants.BASE_URL}/api/cart/${id}/purchase_v2`, data );
        },
        delete: ( id ) => {
            return fetchAPI.delete( `${apiConstants.BASE_URL}/api/cart/${id}` );
        },
        addShippingOption: ( id, data ) => {
            return fetchAPI.put( `${apiConstants.BASE_URL}/api/cart/${id}/shippingOption`, transformCartAddShippingOption( data ) );
        },
        getShippingOptions: ( id, data ) => {
            return fetchAPI.get(
                `${apiConstants.BASE_URL}/api/cart/${id}/shippingOption`,
                apiUtilsNormalizeQueryParams( data )
            );
        },
        createInvoice: ( id, data ) => {
            return fetchAPI.post(
                `${apiConstants.BASE_URL}/api/cart/${id}/invoice`,
                data
            );
        }
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}
