/** @format */

import loyaltyConstants from '@core/utils/constants/loyalty';
import purchaseFlowConstants from '@core/utils/constants/purchaseFlow';

import TheLeague from './views/TheLeague/TheLeague.vue';
import commonEn from './locales/_common/en.json';
import sharedEn from './locales/shared/en.json';
import mainEn from './locales/main/en.json';

import themeColors from './theme-colors-export-js.scss';

export default {
    name: 'fanatics',
    i18n: {
        languages: ['en'],
        locales: {
            _common: {
                en: commonEn,
            },
            shared: {
                en: sharedEn,
            },
            main: {
                en: mainEn,
            },
        },
    },
    store: {
        stores: {
            appTenant: {
                config: {
                    loyalty: {
                        currencyType: loyaltyConstants.CURRENCY_TYPES.MONEY,
                        type: loyaltyConstants.TYPES.NONE,
                    },
                    purchaseFlow: {
                        eventTicket: {
                            type: purchaseFlowConstants.TYPES.DIRECT,
                        },
                    },
                    authentication: {
                        type: null,
                    },
                    ACLAllow: {
                        guestCanPurchase: true,
                    },
                },
            },
        },
    },
    theme: {
        vuetify: themeColors,
        vuetifyOld: {
            primary: '#C63A21',
            secondary: '#E53C2E',
            tertiary: '#6B686B',
            quaternary: '#242424',
            quinary: '#F0F2F3',
            senary: '#C2C2C2',
            septenary: '#DDE0E0',
            accent: '#E53C2E',
            anchor: '#3863A3',
            error: '#E92505',
            success: '#477B0D',
            warning: '#FFC203',
            info: '#091F40',
            // gradients
            gradientPrimary1: '#091F40',
            gradientPrimary2: '#091F40',
            gradientSecondary1: '#10427A',
            gradientSecondary2: '#E62917',
            gradientRadial1: '#FFEE00',
            gradientRadial2: '#FF9F00',
            // custom variables for components
            homeBannerGradientStart: '#091F40',
            homeBannerGradientEnd: '#091F40',
            homeBannerSearchButtonBgColor: '#091F40',
            footerGradientStart: '#F4F5F5',
            footerGradientEnd: '#F4F5F5',
            calendar: '#091F40',
            backgroundTimer: '#091F40',
            backgroundSuccess: '#247E0C',
            overlay: '#000000',
            shadow: '#D5DADF',
            bonusPointsChipBgColor: '#FFFFFF',
            bonusPointsChipColor: '#E53C2E',
            favoriteMarked: '#F80445',
            favoriteUnMarked: '#FFFFFF',
        },
    },
    imagePaths: {
        BANNERS: {
            SEARCH: '/banners/static_page_banner.png',
            STATIC: '/banners/static_page_banner.png',
            VENUE: '/banners/static_page_banner.png',
            USER_FAVORITES: '/banners/static_page_banner.png',
        },
        FALLBACKS: {
            NO_DATA: '/fallbackImages/no_events_available.png',
            NO_TICKETS: '/fallbackImages/no_tickets_available.png',
            NOT_FOUND: '/fallbackImages/404.png',
            ERROR: '/fallbackImages/500.png',
            SPORTS: '/fallbackImages/sports.png',
            ARTS: '/fallbackImages/arts.png',
            CONCERTS: '/fallbackImages/concerts.png',
            FAMILY: '/fallbackImages/family.png',
            OTHER_EVENTS: '/fallbackImages/other_events.png',
        },
    },
    routes: {
        add: [
            {
                name: 'leagues',
                path: '/leagues/:id',
                component: TheLeague,
                meta: {
                    isPublic: true,
                },
            },
        ],
    },
};
