<!-- @format -->

<template>
    <div>
        <transition name="fade">
            <div
                v-if="showOverlay && $vuetify.breakpoint.mdAndUp"
                class="overlay u-alpha-background"
                @click="setShowOverlay(false)"
                @keypress.esc="setShowOverlay(false)"></div>
        </transition>

        <BaseBanner
            ref="searchBanner"
            class="gst-banner--home d-flex flex-column">
            <template v-slot:gst-banner-content>
                <div
                    class="d-flex align-center justify-center gst-banner--home__ticketmaster-tickets">
                    <div
                        class="d-flex align-center justify-center gst-banner--home__ticketmaster-tickets-content">
                        <span>
                            {{ $t('ticketsFromTicketmaster') }}
                        </span>
                        <BaseIcon symbol-id="logo_ticketmaster" />
                    </div>
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h1
                    class="gst-home__title"
                    v-html="$t('title', { interpolation: { escapeValue: false } })"></h1>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h3
                    v-if="discountsTopBannerMessage"
                    class="gst-home__subtitle"
                    v-html="discountsTopBannerMessage"></h3>
                <div class="gst-home__search content-max-width mt-7 mt-md-12">
                    <SearchFieldBanner
                        :show-overlay="showOverlay"
                        @click.native="setShowOverlay(true)"
                        @keydown.esc.native="setShowOverlay(false)" />
                </div>
            </template>
        </BaseBanner>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SearchBanner from '@core/views/home/TheHome/SearchBanner.vue?CORE';

export default {
    name: 'SearchBanner',
    extends: SearchBanner,
    computed: {
        ...mapGetters({
            getDiscounts: 'appTenant/getDiscounts',
        }),
        discountsTopBannerMessage() {
            return this.getDiscounts[0]?.messages.topBanner;
        },
    },
};
</script>
