import {
    getSetting as getSettingStorageUtils,
    saveSetting as saveSettingStorageUtils
} from '@core/utils/storageUtils';
import { uuid as isValidUUID } from '@core/utils/validate';

const LOCAL_STORAGE_KEYS = {
    EDUCATIONAL_FLOW: 'educationalFlow',
    USER_LOGIN_EMAIL: 'userLoginEmail',
    EDUCATIONAL_FLOW_INITIATED: 'educationalFlowInitiated',
    USER_TOKEN: 'gstUserToken'
};

const service = {
    educationalFlowProvider: {
        get() {
            const educationalFlow = getSettingStorageUtils( LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW );
            if( educationalFlow ) {
                return educationalFlow.value;
            }
        },
        set( educationalFlow ) {
            const expireTime = 30 * 12 * 60 * 60 * 1000; // 30 days
            saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW, educationalFlow, expireTime );
        },
    },
    educationalFlowInitiatedProvider: {
        get() {
            return getSettingStorageUtils( LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW_INITIATED );
        },
        set( educationalFlowInitiated ) {
            saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW_INITIATED, educationalFlowInitiated );
        }
    },
    // /**
    //  * Deprecated..it will be use in the future when we will drop vuex-persist
    //  */
    // userLoginEmailProvider: {
    //     get() {
    //         const userLogin = getSettingStorageUtils( LOCAL_STORAGE_KEYS.USER_LOGIN_EMAIL );
    //         if( userLogin ) {
    //             return userLogin.value;
    //         }
    //     },
    //     set( userLogin ) {
    //         const expireTime = 90 * 24 * 60 * 60 * 1000; // 90 days
    //         saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.USER_LOGIN_EMAIL, userLogin, expireTime );
    //     },
    //     delete() {
    //         saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.USER_LOGIN_EMAIL, null );
    //     },
    // },
    userToken: {
        set(token) {
            if (isValidUUID(token)) {
                const expireTime = 24 * 3600 * 1000; // 24 hours
                saveSettingStorageUtils(LOCAL_STORAGE_KEYS.USER_TOKEN, token, expireTime);
            }
        },
        get() {
            const token = getSettingStorageUtils(LOCAL_STORAGE_KEYS.USER_TOKEN);
            if (token?.value && isValidUUID(token.value)) {
                return token.value;
            } else {
                localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TOKEN);
                return null;
            }
        },
        refresh() {
            const value = service.userToken.get();
            if (value) {
                service.userToken.set(value);
            }
        },
    },
};

export default service;
