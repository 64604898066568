<!-- @format -->

<template>
    <div class="gst-notification-terms-wrapper u-width-100 content-max-width">
        <div class="gst-notification-terms content-max-width">
            <span class="gst-notification-terms__text">
                {{ $t('labels.agreeText1') }}
                <a
                    href="https://help.ticketmaster.com/hc/en-us/articles/21729046347921-TM-Fanatics-Purchase-Experience-Terms-of-Use"
                    target="_blank"
                    @click="onClickLinkDo">
                    {{ $t('_common:terms.termsOfUse') }}
                </a>
                {{ $t('labels.agreeText2') }}
                <a
                    href="https://privacy.ticketmaster.com/privacy-policy"
                    target="_blank"
                    @click="onClickLinkDo">
                    {{ $t('_common:terms.privacyPolicy') }}
                </a>
                {{ $t('labels.agreeText3') }}
                <a
                    href="https://www.fanatics.com/what-is-your-privacy-policy/ch-2349"
                    target="_blank"
                    @click="onClickLinkDo">
                    {{ $t('_common:terms.privacyPolicy') }}
                </a>
            </span>
            <div
                class="gst-notification-terms__close"
                tabindex="0"
                @click="onClickCloseDo"
                @keypress.esc="onClickCloseDo">
                <BaseIcon
                    class="gst-close-icon-svg u-mouse-pointer"
                    symbol-id="icons--close"
                    height="24"
                    width="24" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

const STORE_NOTIFICATION_TERMS = 'notificationTerms';

export default {
    name: 'NotificationTerms',
    components: {
        BaseIcon,
    },
    i18nOptions: {
        namespaces: 'shared',
        keyPrefix: 'components.notifications.notificationTerms',
    },
    computed: {
        ...mapGetters({
            show: `${STORE_NOTIFICATION_TERMS}/getVisible`,
        }),
    },
    methods: {
        ...mapActions({
            close: `${STORE_NOTIFICATION_TERMS}/close`,
            acceptTerms: `${STORE_NOTIFICATION_TERMS}/accept`,
        }),
        onClickLinkDo(e) {
            e.stopPropagation();
        },
        onClickCloseDo() {
            this.close();

            // if on checkout wizard going back and forth on steps but notification closed from snackbar,
            // don't show again
            this.acceptTerms();
        },
        toggleNotificationTermsSpacingClass(notificationTermsVisibility) {
            const app = document.querySelector('.v-application');
            notificationTermsVisibility
                ? app.classList.add('gst-notification-terms-spacing')
                : app.classList.remove('gst-notification-terms-spacing');
        },
    },
    mounted() {
        this.toggleNotificationTermsSpacingClass(this.show);
    },
    destroyed() {
        this.toggleNotificationTermsSpacingClass(this.show);
    },
};
</script>

<style lang="scss" scoped>
@import '@scssVariables';
@import '@scssMixins';

.gst-notification-terms-wrapper {
    position: fixed;
    left: 50%;
    bottom: theme-spacing(2);
    transform: translate(-50%, 0);
    z-index: 9999;

    @include mobile-only {
        padding: theme-spacing(6, 3, 4);
    }
}

.gst-notification-terms {
    display: flex;
    width: 100%;
    padding: theme-spacing(2, 3);
    background: theme-color('info');
    border-radius: border-radius('s');
    flex-direction: row;
    align-items: center;

    .gst-notification-terms__text {
        line-height: normal;
        color: theme-color('white');
        font-size: font-size('xxxs');
        font-weight: font-weight('regular');
        font-family: $font-family;
        font-style: normal;
        flex-grow: 1;

        span {
            display: inline-block;
        }

        a {
            color: theme-color('white');
            font-weight: font-weight('bold');
            text-decoration: underline;
            text-transform: uppercase;
        }
    }

    .gst-notification-terms__close ::v-deep .gst-svg-icon {
        fill: theme-color('white') !important;
    }

    .gst-notification-terms__close {
        display: flex;
        height: 24px;
        width: 60px;
        justify-content: flex-end;
    }
}
</style>
