/** @format */

import Vue from 'vue';
import appTenantFactory from '@core/helpers/appTenantFactory';
import '@core/scss/main.scss';
import { isProduction } from '@core/utils/environmentUtils';

import config from './config';
import discountTokenInterceptor from './api/interceptors/discountToken';

discountTokenInterceptor.addToRequest();

const loadExternalScript = () => {
    const PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH;
    const STAGING_SRC = `${PUBLIC_PATH}/fanatics-script-staging.js`;
    const PRODUCTION_SRC = `${PUBLIC_PATH}/fanatics-script-production.js`;
    const src = isProduction() ? PRODUCTION_SRC : STAGING_SRC;

    return new Promise((resolve) => {
        const options = {
            async: true,
        };

        Vue.loadScript(src, 'text/javascript', options)
            .then(() => resolve(true))
            .catch(() => resolve(false));
    });
};

loadExternalScript();

appTenantFactory.create(config);
import './scss/main.scss';
