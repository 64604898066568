/** @format */

const EVENT_TYPES = {
    PAGE_VIEW: 'page-view',
    PAGE_RESIZE: 'page-resize',
    PAGE_SCROLL: 'page-scroll',
    PURCHASE_SUCCESS: 'purchase-success',
};

/**
 * Iframe Event - communication with Iframe
 */

export default {
    sendPageViewEvent() {
        // eslint-disable-next-line no-undef
        fanaticsEvents.event(EVENT_TYPES.PAGE_VIEW, {
            url: window.location.href,
            referrer: document.referrer,
        });
    },
    /**
     *
     * @param {*} payload
     * top or bottom
     */
    sendPageScrollEvent(payload = 'top') {
        // eslint-disable-next-line no-undef
        fanaticsEvents.event(EVENT_TYPES.PAGE_SCROLL, payload);
    },
    /**
     *
     * @param {String|Number} payload
     * 'viewport' or 'number' ( in px )
     */
    sendPageResizeEvent(payload = 'viewport') {
        // eslint-disable-next-line no-undef
        fanaticsEvents.event(EVENT_TYPES.PAGE_RESIZE, payload);
    },
    sendPurchaseSuccessEvent(payload) {
        // eslint-disable-next-line no-undef
        fanaticsEvents.event(EVENT_TYPES.PURCHASE_SUCCESS, payload);
    },
};
