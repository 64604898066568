/** @format */

export default function ({ tm_attraction_id, name, logo, banner_url }) {
    return {
        tmAttractionId: tm_attraction_id,
        name,
        logoUrl: logo,
        bannerUrl: banner_url,
    };
}
