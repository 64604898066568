<!-- @format -->

<template>
    <v-app-bar
        height="60"
        app
        flat
        class="gst-fanatics-header">
        <template v-if="showSearch">
            <HeaderSearchBar v-if="!$vuetify.breakpoint.mdAndUp" />
            <SearchKeywordWithAutocomplete
                v-else
                v-model="keyword"
                redirect-to-search-on-enter
                :style="{ minWidth: '360px' }"
                class="ml-0 gst-header__search-keyword-with-autocomplete" />
        </template>
        <v-spacer />

        <BaseIcon
            symbol-id="logo_ticketmaster"
            class="gst-header-icon" />
    </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';
import SearchKeywordWithAutocomplete from '@core/shared/components/search/SearchKeywordWithAutocomplete.vue';
import Header from '@core/shared/layouts/components/Header?CORE';
import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
import HeaderSearchBar from '@tenant/components/search/HeaderSearchBar.vue';

export default {
    name: 'Header',
    extends: Header,
    components: {
        SearchKeywordWithAutocomplete,
        BaseIcon,
        HeaderSearchBar,
    },
    testIdOptions: {
        keyPrefix: 'layout.default.theHeader',
    },
    props: {
        withSearch: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        isSearchFocused: false,
    }),
    computed: {
        keyword: {
            get() {
                return this.$store.state.searchState.current.keyword;
            },
            set(value) {
                this.updateSearch({ keyword: value });
            },
        },
        showSearch() {
            return this.$route.name !== 'cartCheckout';
        },
    },
    methods: {
        ...mapActions({
            updateSearch: 'searchState/update',
        }),
    },
};
</script>

<style lang="scss">
@import '@scssMixins';
@import '@scssVariables';

.gst-fanatics-header {
    padding-bottom: 6px !important;
    background-color: theme-color('white') !important;
    border-bottom: 1px solid theme-color('quinary') !important;

    .gst-header__search-keyword-with-autocomplete {
        .v-input__slot {
            top: 50%;
            height: 40px !important;
            min-height: 0 !important;
        }

        .v-text-field--solo > .v-input__control {
            border-radius: border-radius('m') !important;
            min-width: 40px !important;
            min-height: 0 !important;
        }

        .v-text-field--solo > .v-input__control > .v-input__slot {
            background-color: theme-color('quinary') !important;
        }

        .gst-search-input {
            input {
                color: theme-color('tertiary') !important;
                font-size: 14px !important;
            }
        }
    }

    @include mobile-only {
        svg.gst-base-icon.gst-header-icon {
            max-width: 72px !important;
        }
    }
}
</style>
