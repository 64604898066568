<!-- @format -->

<template>
    <div class="gst-top-events-card-list">
        <h4
            ref="cityDropDown"
            class="mt-8">
            {{ $t('title') }}
            <span class="inline">
                <CitySearchStoreDropDown
                    v-model="selectedCity"
                    :data-test-id="$testId('filter.city')"
                    :has-current-location-option="true"
                    :has-search-option="true"
                    :limit="5"
                    :clearable="false"
                    :toggle-text-color="false"
                    :toggle-icon-color="false"
                    @open="onCityDropDownOpenDo" />
            </span>
        </h4>
        <DataLoading
            v-if="loading"
            class="mt-5 mb-5 pt-5 pb-5" />
        <div v-else>
            <NoData
                v-if="!events.length"
                :message="$t('messages.noData')"
                class="pt-4" />
            <div class="container pa-2 ma-0 mt-2 container--fluid grid-list-xl align-center">
                <div class="row wrap">
                    <div
                        v-for="(event, i) in events"
                        :key="i"
                        class="pa-2 col col-xs-12 col-sm-6 col-md-4">
                        <EventCardVariant2
                            :event="event"
                            :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                            :data-test-id="$testId(`card.${i + 1}`)" />
                    </div>
                </div>
                <SeeMoreButton
                    v-if="!allItemsLoaded"
                    :data-test-id="$testId('buttons.seeMore')"
                    class="mx-auto d-flex mt-4"
                    :loading="loadingSeeMore"
                    @click="loadNextPage()" />
            </div>
        </div>
    </div>
</template>
<script>
import TopEventsCardList from '@core/views/home/TheHome/TopEventsCardList.vue?CORE';

export default {
    name: 'TopEventsCardList',
    extends: TopEventsCardList,
};
</script>
