<!-- @format -->

<template>
    <div class="gst-league-card">
        <div
            class="gst-league-card__content d-flex align-center u-mouse-pointer"
            tabindex="0"
            @click="goToAttractionsPage"
            @keypress.enter="goToAttractionsPage">
            <div class="gst-league-card__image mr-4">
                <BaseImage
                    :src="logoUrl"
                    height="48"
                    width="48"
                    cover />
            </div>
            <div class="gst-league-card__title">
                {{ name }}
            </div>
        </div>
    </div>
</template>

<script>
import BaseImage from '@core/shared/components/misc/BaseImage.vue';

export default {
    name: 'LeaguePageTeamCard',
    components: {
        BaseImage,
    },
    props: {
        name: {
            type: String,
            default: '',
            required: true,
        },
        logoUrl: {
            type: String,
            default: '',
            required: true,
        },
        teamId: {
            type: String,
            default: '',
            required: true,
        },
    },
    methods: {
        goToAttractionsPage() {
            this.$router.push({ name: 'attractionPartner', params: { id: this.teamId } });
        },
    },
};
</script>

<style lang="scss">
@import '@scssMixins';
@import '@scssVariables';

.gst-league-card {
    .gst-league-card__content {
        width: fit-content;

        .gst-league-card__title {
            color: theme-color('quaternary');
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.471px;
        }

        .gst-league-card__image {
            .v-image {
                border-radius: 10px !important;
            }
        }
    }
}
</style>
