<!-- @format -->

<template>
    <div
        class="main default-layout u-width-100 container container--fluid main-content"
        :class="{ 'main--show-header': showHeader }">
        <Header v-if="showHeader" />
        <slot></slot>
        <transition name="gst-fade-notification-terms">
            <NotificationTerms v-if="show" />
        </transition>
    </div>
</template>

<script>
import NotificationTermsLayoutMixin from '@tenant/mixins/NotificationTermsLayoutMixin';
import Header from './components/Header';

export default {
    name: 'DefaultLayout',
    components: {
        Header,
    },
    mixins: [NotificationTermsLayoutMixin],
    computed: {
        showHeader() {
            return this.$route.name !== 'home';
        },
    },
};
</script>
