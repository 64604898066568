import { formatQueryParam as dateUtilsFormatQueryParam } from '@core/utils/dateUtils';
import offerConstants from '@core/utils/constants/offer';

export default function ( ticket, hotelReservation, offer, searchedLocation, token ) {
    let ret = {};

    if ( ticket ) {
        const { eventId, offers, seats, row, section, accessibility, selection, fanSessionResaleId } = ticket;
        ret = {
            ...ret,
            ticket: {
                items: [ {
                    productId: eventId,
                    offers,
                    section,
                    row,
                    seats,
                    accessibility,
                    selection,
                    fanSessionResaleId
                } ]
            }
        };
    }

    if ( hotelReservation ) {
        const { hotel, accommodation, room, originatingEventId } = hotelReservation;
        const { selectedRate } = room;
        const oldTotalPrice = selectedRate.oldTotalPrice;

        const roomRate = {
            rateId: selectedRate.id,
            currency: selectedRate.currency,
            inventory: selectedRate.inventory,
            netPrice: selectedRate.netPrice,
            totalPrice: selectedRate.totalPrice,
            mealPlan: selectedRate.mealPlan,
            fees: selectedRate.fees.map( transformRoomRateFee ),
            rateType: selectedRate.type,
            paymentType: selectedRate.paymentType,
            cancellationPolicies: ( offer && offer.type === offerConstants.TYPES.HOTEL_ONLY ) ? null : selectedRate.cancellationPolicies.map( item => {
                return {
                    from: item._originalFromAt,
                    amount: item.amount,
                    percent: item.percent,
                    numberOfNights: item.numberOfNights
                };
            } )
        };

        if ( selectedRate.giftCard ) {
            roomRate.giftCard = { ...selectedRate.giftCard };
        }

        ret = {
            ...ret,
            hotelRoom: {
                hotelId: hotel.id,
                stayRange: {
                    checkin: dateUtilsFormatQueryParam( accommodation.startDateTime ),
                    checkout: dateUtilsFormatQueryParam( accommodation.endDateTime ),
                },
                roomCriteria: {
                    roomCount: accommodation.roomsCount,
                    adultCount: accommodation.guests.adultsCount,
                    childCount: accommodation.guests.childrenCount,
                    childAges: accommodation.guests.childAges || [ ]
                },
                roomId: room.id,
                roomRate: oldTotalPrice ?
                    {
                        ...roomRate,
                        oldTotalPrice: oldTotalPrice,
                    }
                    : roomRate
            },
            originatingEventId: originatingEventId,
        };
    }

    if ( offer ) {
        ret = { ...ret, offer: { ...offer } };
    }
    if ( searchedLocation ) {
        ret = { ...ret, searchedLocation: { ...searchedLocation } };
    }
    if ( token ) {
        ret = { ...ret, token: token };
    }

    return ret;
}

const transformRoomRateFee = ( { dateRange, fee } ) => {
    const { typeIncluded, ...rest } = fee;

    return {
        ...dateRange,
        fee: {
            included: typeIncluded,
            ...rest
        }
    };
};
